.section.portfolio-list {
    margin-top: 20px;
    padding-bottom: 40px;
}
.portfolio-list .title {
    padding: 30px 0 0;
}

.portfolio-list .feature-portfolios {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.portfolio-list .feature-portfolios .card {
    background: var(--lightGray);
    max-height: 300px;
    width: calc(33.33% - 20px);
    margin-top: 40px;
    position: relative;
    border: 1px solid var(--lightGray);
    padding: 5px;
}

.portfolio-list .feature-portfolios .portfolio-name {
    font-family: var(--designFont);
    font-size: 30px;
}

.portfolio-list .feature-portfolios .img-box {
    height: 100%;
    text-align: center;
    box-shadow: 0 3px 10px 5px rgba(0,0,0,0.05);
    overflow-y: hidden;
}

.portfolio-list .feature-portfolios img { 
    width: 100%;
    height: auto;
}

.portfolio-list .feature-portfolios .card-content {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background: rgba(114, 182, 38, 0.9);
    opacity: 0;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--light);
    text-align: center;
    text-decoration: none;
    pointer-events: none;
    transform: scale(0);
    transform-origin: center;
}

.portfolio-list .feature-portfolios .card:hover .card-content {
    transform: scale(1);
    opacity: 1;
}

.portfolio-list .feature-portfolios .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    margin: 10px auto;
}

.portfolio-list .portfolio-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

@media screen and (max-width: 1140px) {
    .portfolio-list .feature-portfolios {
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .portfolio-list .feature-portfolios .card {
        width: 100%;
        margin: 20px 0;
    }
}