.section.banner {
    padding: 0 5%;
    margin: 60px auto 0;
}

.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banner-content {
    max-width: 700px;
}

.banner-content h2 {
    font-family: var(--designFont);
    font-size: 40px;
    font-weight: 700;
}

.banner-content h1 {
    font-family: var(--designFont);
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 20px;
}

.banner-content p {
    margin-bottom: 10px;
    color: var(--text);
}

.banner-content .btn {
    display: inline-block;
    margin-top: 40px;
}

.banner-image .img-container {
    height: 420px;
    position: relative;
}


@media screen and (max-width: 1100px) {
    .banner-content {
        max-width: 500px;
    }
}

@media screen and (max-width: 950px) {
    .banner-content h2 {
        font-size: 30px;
    }
    
    .banner-content h1 {
        font-size: 40px;
    }
    
    .banner-content p {
        margin-bottom: 10px;
        color: var(--text);
    }
    
    .banner-image .img-container {
        height: 300px;
    }

    .banner-image .img-container img {
        width: auto;
    }
}

@media screen and (max-width: 700px) {
    .banner {
        display: block;
    }

    .banner-content {
        max-width: 100%;
        padding: 40px 0;
    }
}