.section.contact {
    padding-bottom: 60px;
}

.contact .title {
    padding: 30px 0;
}

.contact .contact-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.contact .spacer {
    flex-basis: 4%;
}

.contact .contact-info,
.contact .contact-img {
    flex-basis: 50%;
}

.contact-info .address,
.contact-info .social-info {
    margin-bottom: 50px;
}

.contact-info h3 {
    margin-bottom: 10px;
}

.contact-info .link {
    color: var(--primary);
    text-decoration: none;
    transition: all 0.5s;
    display: inline-block;
}

.contact-info .link:hover {
    transform: scale(1.05);
}
.contact-info .social-icons {
    margin: 0;
    margin-top: 20px;
}

.contact-info .social-icons .icon-wrapper {
    color: var(--primary);
}

.contact-info .social-icons .icon-wrapper:hover {
    color: var(--light);
}

.contact-info .copy-right i {
    font-size: 14px;
}

.contact .contact-img {
    display: flex;
    justify-content: center;
}

.contact .contact-img img {
    width: 100%;
    height: 100%;
}

.scroll-top {
    position: absolute;
    bottom: 120px;
    right: 0;
    transform: rotate(-90deg);
}

.scroll-top a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--text);
    transition: all 0.5s;
    letter-spacing: 1px;
}

.scroll-top:hover a {
    transform: translateX(6px);
}

.scroll-top .icon {
    font-size: 25px;
}

@media screen and (max-width: 840px) {
    .contact .contact-box {
        width: 100%;
        display: block;
    }
    
    .contact .spacer {
        flex-basis: 4%;
    }
    
    .contact .contact-info,
    .contact .contact-img {
        flex-basis: 50%;
    }

    .scroll-top {
        position: absolute;
        bottom: 400px;
        right: 0;
        transform: rotate(-90deg);
    }
}