@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Molle:ital@1&display=swap');

.nav-bar {
    background-color: var(--primary);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.header {
    max-width: 1366px;
    height: 60px;
    padding: 10px 5%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left,
.right {
    display: flex;
    align-items: center;
}

.logo-wrapper {
    text-decoration: none;
    color: inherit;
  }

.logo {
    /* font-family: monoton; */
    font-family: molle;
    font-size: 40px;
    margin-right: 40px;
}

.social-icons {
    margin-left: 20px;
}

.social-icons .icon-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    transition: all 0.5s;
    margin: 0 5px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 3px 2px 1px rgba(0,0,0,0.1);
    color: inherit;
}   

.social-icons .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.social-icons .icon-wrapper:hover {
    background-color: var(--dark);
    transform: translateY(-5px);
    color: var(--lightGray);
    box-shadow: 0 0px 2px 1px rgba(0,0,0,0.1);
}

.hamburger-menu {
    font-size: 40px;
    margin-left: 30px;
    cursor: pointer;
    transition: all 0.3s;
}

.hamburger-menu:hover {
    color: var(--lightGray);
    transform: scale(1.15);
}


@media screen and (max-width: 660px) {
    .left .logo {
        margin-right: 0;
    }
    
    .left .design-dash {
        display: none;
    }
    
    .left .social-icons {
        display: flex;
        margin-right: 0;
    }
    
    .right {
        display: flex;
        align-items: center;
    }
    
    .right .btn {
        display: none;
    }
    
    .right .hamburger-menu {
        margin-left: 0;
    }
}

@media screen and (max-width: 400px) {
    .left .social-icons {
        display: none;
    }
    .right .btn {
        display: inline-block;
        margin-right: 10px;
    }
}