.about {
    position: relative;
    z-index: 2;
}

.about .title {
    padding: 30px 0;
}

.about .about-content {
    display: flex;
    justify-content: space-between;
}

.about .about-img,
.about .about-desc {
    flex-basis: 50%;
}

.about .about-img {
    display: flex;
    justify-content: center;
}

.about .about-img img {
    width: auto;
    height: 390px;
    position: relative;
}

.about .about-desc .quote {
    display: flex;
    align-items: center;
    color: var(--text);
    font-weight: bold;
}

.about .about-desc p {
    margin-bottom: 20px;
}

.about .about-desc .icon {
    font-size: 60px;
    margin-right: 20px;
    color: var(--text);
}

.about .about-desc .skills {
    display: flex;
}

.about .skills ul {
    list-style: square;
}

@media screen and (max-width:768px) {
    .about .about-content {
        display: block;
    }
}