.skills {
    padding: 40px 0;
}

.skills .title {
    padding: 30px 0;
}

.skills-line {
    border: 1px solid var(--text);
    position: relative;
}

.skills-line span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 60px;
    width: 60px;
    background: var(--text);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--light);
}

.skills-line span:first-child {
    left: -5px;
}

.skills-line span:last-child {
    right: -5px;
}

.skills-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px auto 50px;
}

.skills-content .skill-card {
    position: relative;
    margin-top: 0px;
}

.skills-content .skill-card::before {
    content: '';
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    height: 100px;
    border: 1px solid var(--text);
}

.skills-content .skill-card:nth-child(2) {
    margin-top: 100px;
}

.skills-content .skill-card:nth-child(2)::before {
    top: -120px;
    height: 150px;
}

.skills-content h3 {
    position: relative;
    padding: 30px;
    background: var(--primary);
    color: var(--light);
    margin-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width: 320px;
}

.skills-content h3::before {
    content: '';
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
    clip-path: polygon(50% 35%, 0% 100%, 100% 100%);
    background: linear-gradient(to bottom,var(--text),var(--primary));
    width: 30px;
    height: 30px;
}

.skills-content ul {
    padding: 20px 40px;
    list-style: square;
    background: var(--lightGray);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.skills-content ul li {
    letter-spacing: 1px;
    font-weight: 400;
}

@media screen and (max-width: 1280px) {
    .skills-line {
        display: none;
    }

    .skills-content {
        display: flex;
        flex-wrap: wrap;
    }

    .skills-content .skill-card::before {
        display: none;
    }

    .skills-content .skill-card:nth-child(2) {
        margin-top: 0px;
    }

    .skills-content h3::before {
        display: none;
    }
}

@media screen and (max-width: 860px) {
    .skills-content {
        display: block;
    }

    .skills-content h3 {
        min-width: auto;
    }

    .skills-content ul {
        padding: auto;
    }
}