@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

:root {
  /* Font Family  */
  --designFont: "Cormorant Upright", serif;
  --generalFont: "Poppins", sans-serif;
  /* Colors */
  --primary: #72b626;
  --light: #fff;
  --dark: #161616;
  --text: #333;
  --gray: #777;
  --lightGray: #eee;
  --lighterGray: #f1f1f1;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: var(--generalFont);
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 950px) {
  body {
    font-size: 15px;
  }
}

.btn {
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s;
}

.btn:hover {
  transform: scale(1.05);
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
}

.primary-btn {
  background: var(--primary);
  color: var(--light);
  border: 2px solid var(--light);
  outline: 2px solid var(--primary);
}

.primary-btn:hover {
  border-color: var(--primary);
}

.primary-btn-outline {
  border: 1px solid var(--dark);
  color: var(--dark);
}

.secondary-btn {
  background: var(--light);
  color: var(--dark);
  border: 2px solid var(--primary);
  outline: 2px solid var(--light);
}

.secondary-btn:hover {
  border-color: var(--light);
}

.secondary-btn-outline {
  border: 1px solid var(--light);
  color: var(--light);
}

.sections {
  width: 100%;
  /* For Snap Scroll */
  /* scroll-snap-type: y mandatory;   
  height: calc(100vh - 80px);
  overflow: auto; */
  position: relative;
  top: 80px;
  scrollbar-width: none; /* Firefox */
  --webkit-scrollbar-width: none;
}

.section {
  max-width: 1366px;
  margin: auto;
  padding: 80px 5% 0;
  /* height: calc(100vh - 80px); */
  scroll-snap-align: start;
  position: relative;
}

.img-fluid {
  width: 100%;
  height: 100%;
}

.title {
  text-align: center;
  text-transform: uppercase;
}

.title h2 {
  font-family: var(--designFont);
  font-size: 40px;
  font-weight: 700;
}

.title span {
  letter-spacing: 2px;
}
