.section.portfolio-detail {
    margin-top: 20px;
    padding-bottom: 40px;
}

.portfolio-detail .back-link {
    text-decoration: none;
    display: inline-block;
    position: sticky;
    top: 100px;
}

.portfolio-detail .back-link .icon {
    margin-right: 10px;
    position: relative;
    top: 3px;
}

.portfolio-detail .title {
    padding: 30px 0;
}

.portfolio-detail .portfolio-image {
    text-align: center;
    max-width: 900px;
    margin: auto;
}

.portfolio-detail .portfolio-image img {
    outline: 2px solid var(--lightGray);
    outline-offset: 5px;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .portfolio-detail .title strong {
        display: block;
    }
}