.navigation-menu {
    width: 100vw;
    height: 100vh;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
}

.navigation-menu.active {
    z-index: 9;
    opacity: 1;
}

.navigation-menu ul {
    list-style: none;
    text-align: center;
}

.navigation-menu li a {
    display: inline-block;
    font-size: 25px;
    text-decoration: none;
    color: var(--light);
    font-weight: bold;
    margin: 10px;
    transition: all 0.3s;
}

.navigation-menu li a:hover {
    color: var(--dark);
}

.navigation-menu li span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    font-size: 200px;
    font-weight: 900;
    opacity: 0;
    color: var(--lightGray);
    transition: all .3s;
    pointer-events: none;
}

.navigation-menu .home-link:hover .home-link-bg {
    letter-spacing: 40px;
    opacity: 0.4;
    pointer-events: none;
}

.navigation-menu .about-link:hover .about-link-bg {
    letter-spacing: 40px;
    opacity: 0.4;
    pointer-events: none;
}

.navigation-menu .skills-link:hover .skills-link-bg {
    letter-spacing: 40px;
    opacity: 0.4;
    pointer-events: none;
}

.navigation-menu .portfolio-link:hover .portfolio-link-bg {
    letter-spacing: 10px;
    opacity: 0.4;
    pointer-events: none;
}

.navigation-menu .blog-link:hover .blog-link-bg {
    letter-spacing: 10px;
    opacity: 0.4;
    pointer-events: none;
}

.navigation-menu .contact-link:hover .contact-link-bg {
    letter-spacing: 40px;
    opacity: 0.4;
    pointer-events: none;
}
